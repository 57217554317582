import { Auth } from '@aws-amplify/auth';
import {
  HeadObjectCommand,
  HeadObjectCommandOutput,
  S3Client,
} from '@aws-sdk/client-s3';
import awsConfig from '../hooks/apollo/awsConfig';

export const getUrlFilename = (url: string) => {
  return url.split('/').pop() as string;
};

export const getHeadObjectFromS3 = async ({
  Key,
}: {
  Key: string;
}): Promise<HeadObjectCommandOutput> => {
  const credentials = await Auth.currentCredentials();

  const s3Client = new S3Client({
    region: awsConfig.s3Config.files_s3_region,
    credentials: Auth.essentialCredentials(credentials),
  });

  const headObjectParams = {
    Bucket: awsConfig.s3Config.files_s3_bucket,
    Key,
  };

  const command = new HeadObjectCommand(headObjectParams);

  return s3Client.send(command);
};
