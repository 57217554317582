import autogeneratedSettings from 'aws-exports';

const config = {
  awsPublic: {
    endpoint: autogeneratedSettings.aws_appsync_graphqlEndpoint,
    authenticationType: 'AWS_IAM',
    region: autogeneratedSettings.aws_appsync_region,
  },
  awsPrivate: {
    endpoint: autogeneratedSettings.aws_appsync_graphqlEndpoint,
    authenticationType: autogeneratedSettings.aws_appsync_authenticationType,
    region: autogeneratedSettings.aws_appsync_region,
    aws_user_pools_id: 'us-east-1_auCDskee6',
  },
  awsAmplifyConfig: autogeneratedSettings,
  s3Config: {
    files_s3_bucket: autogeneratedSettings.aws_user_files_s3_bucket,
    files_s3_region: autogeneratedSettings.aws_user_files_s3_bucket_region,
  },
};

export default config;
