export const defaultPaymentState = {
  fullName: '',
  firstAddress: '',
  secondAddress: '',
  country: '',
  branchCode: '',
  accountNumber: '',
  swiftCode: '',
  documentID: '',
  postCode: '',
};

export const defaultPaymentError = {
  fullName: null,
  firstAddres: null,
  secondAddres: null,
  country: null,
  branchCode: null,
  accountNamber: null,
  swiftCode: null,
  documentID: null,
};
