import { Typography } from '@mui/material';
import { Storage } from 'aws-amplify';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  MAX_PAGINATION_PER_PAGE,
  usePagination,
} from '../../../../../hooks/usePagination';
import Pagination from '../../../../ui/Pagination';

export interface ICreativeInspirationsProps {
  videos: string[];
  hasOngoingUpload: boolean;
  maxInspirationsPerPage?: number;
}

const CreativeInspirations: FC<ICreativeInspirationsProps> = ({
  videos,
  hasOngoingUpload,
  maxInspirationsPerPage = MAX_PAGINATION_PER_PAGE,
}) => {
  const [urls, setUrls] = useState<string[]>([]);
  const { page, slicedItems, handlePageChange } = usePagination({
    items: videos,
    maxItemsPerPage: maxInspirationsPerPage,
  });

  const getVideoUrls = useCallback(async () => {
    const urls = await Promise.all(slicedItems.map((v) => Storage.get(v)));
    setUrls(urls);
  }, [slicedItems]);

  useEffect(() => {
    getVideoUrls();
  }, [videos, page, hasOngoingUpload]);

  const showcaseVideos = useMemo(
    () =>
      urls.map((url) => (
        <div
          key={url}
          className="overflow-hidden w-[180px] h-[265px] rounded-[16px]"
        >
          <video key={url} className="w-full h-auto" src={url}>
            <source src={url} type="video/mp4" />
          </video>
        </div>
      )),
    [urls]
  );

  return (
    <div className="bg-main-black flex flex-col justify-between gap-y-10 rounded-3xl p-6 h-[703px]">
      <div>
        <Typography className="uppercase font-oswald text-white font-[600] ">
          Creative inspiration
        </Typography>
      </div>

      <div className="overflow-auto max-height-[300px] max-width-[200px] flex flex-wrap 2xl:justify-start gap-4">
        {showcaseVideos}
      </div>

      <Pagination
        currentPage={page}
        total={videos.length}
        perPage={maxInspirationsPerPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default CreativeInspirations;
