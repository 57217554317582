import { GridColDef } from '@mui/x-data-grid';
import { ceilToNearestDecimal } from 'utils/utils';

export const TABLE_HEADER: GridColDef[] = [
  {
    field: 'creativeUniqueId',
    headerName: 'Creative unique Id',
    align: 'center',
  },
  {
    field: 'earningsCurrent',
    headerName: 'EarningsCurrent',
    align: 'center',
    valueFormatter: (row, value) =>
      `$${ceilToNearestDecimal(value.earningsCurrent)}`,
  },
  {
    field: 'earningsPrevious',
    headerName: 'Earnings previous',
    align: 'center',
    valueFormatter: (row, value) =>
      `$${ceilToNearestDecimal(value.earningsPrevious)}`,
  },
  {
    field: 'earningsLifetime',
    headerName: 'Earnings lifetime',
    align: 'center',
    valueFormatter: (row, value) =>
      `$${ceilToNearestDecimal(value.earningsLifetime)}`,
  },
  {
    field: 'addEarnings',
    headerName: 'Add earnings',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
  },
];
