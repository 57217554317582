import { ButtonBlack, Center, Column, Row } from 'components/common';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UnAuthRoutes } from '../../utils';
import { LOGIN_OR_SIGN_UP, NAV_BUTTONS } from './constants';

export const Header: FC = () => {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const redirect = (url: string) => {
    window.location.href = `${process.env.REACT_APP_URL}${url}`;
  };

  return (
    <div className="fixed w-[96%] opacity-[black] pt-[20px] pb-[20px] pl-[0px] pr-[0px] h-[100px] z-[11]">
      <div className="bg-white rounded-[70px] shadow-headerShadow h-[60px] flex w-full justify-between items-center pt-[0px] pb-[0px] pl-[8px] pr-[8px]">
        <div className="pl-[24px]">
          <img
            className="h-[26px]"
            src={'/images/logo-long.svg'}
            onClick={() => redirect('')}
          />
        </div>
        <Row className="md-max:pr-[0px] pr-[10px]">
          <Row className="h-full md-max:flex hidden">
            {NAV_BUTTONS.map(({ name, route }, idx) => (
              <Center
                className="text-[#222222] p-[20px] font-inter cursor-pointer"
                key={idx}
                onClick={() => redirect(route)}
              >
                {name}
              </Center>
            ))}
          </Row>
          {isShowMobileMenu ? (
            <Column className="md-max:hidden flex absolute z-[12] w-[200px] w-max-[80%] mt-[330px] bg-white shadow-headerShadow items-start mr-[170px] rounded-[6px]">
              {NAV_BUTTONS.map(({ name, route }, idx) => (
                <Center
                  className="text-[#222222] p-[20px] font-inter cursor-pointer"
                  key={idx}
                  onClick={() => redirect(route)}
                >
                  {name}
                </Center>
              ))}
            </Column>
          ) : null}
          <ButtonBlack
            className="md-max:flex hidden h-[44px]"
            onClick={() => navigate(UnAuthRoutes.Login)}
          >
            {LOGIN_OR_SIGN_UP}
          </ButtonBlack>
          <img
            className="md-max:hidden block h-[26px]"
            src={'/images/menu-mobile-hamburger.svg'}
            onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}
          />
        </Row>
      </div>
    </div>
  );
};
