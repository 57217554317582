import { z } from 'zod';

export default z.object({
  BriefName: z.string(),
  country: z.string(),
  active: z.boolean(),
  objective: z.string(),
  tags: z.string().array(),
  badPractices: z.string().array(),
  goodPractices: z.string().array(),
  messaging: z.string(),
  overview: z.string(),
});

export const MainTabSchema = z.object({
  tone: z.string(),
  tags: z.string().optional().array().optional(),
  overview: z.string().min(10),
  objective: z.string().min(2).max(30),
  messaging: z.string().min(10),
  goodPractices: z.string().optional(),
  goodPracticesSecondary: z.array(z.string()).optional(),
  country: z.string(),
  BriefName: z.string().min(2),
  badPractices: z.string().optional(),
  badPracticesSecondary: z.array(z.string()).optional(),
  active: z.boolean().default(false),
  maybes: z.string().optional(),
  maybesSecondary: z.array(z.string()).optional(),
  overviewVideoUrl: z.string().optional(),
  commonRejectionReasons: z.string().optional(),
  commonRejectionSecondary: z.array(z.string()).optional(),
  mustInclude: z.string().optional(),
  mustNotInclude: z.string().optional(),
});

export const SecondStageSchema = z.object({
  creativeInspirations: z.array(z.string()).optional(),
  brandBriefFilesUrl: z.string().optional(),
});

export const ThirdStageSchema = z.object({
  manualData: z.object({
    tikTokSparkAds: z.boolean().optional(),
  }),
  tiktokData: z
    .object({
      adIdentityId: z.string().default(''),
      adgroupId: z.string().default(''),
      campaignId: z.string().default(''),
      adCaption: z.string().default('').optional(),
      displayName: z.string().default(''),
      tikTokSparkAds: z.boolean().optional(),
      callToAction: z.string().default(''),
      landingPageUrl: z.string().url().default('').optional(),
    })
    .optional(),
  metaData: z
    .object({
      adgroupId: z.string().default(''),
      campaignId: z.string().default(''),
      adCaption: z.string().default(''),
      callToAction: z.string().default(''),
      landingPageUrl: z.string().url().default('').optional(),
    })
    .optional(),
  youtubeData: z
    .object({
      adgroupId: z.string().default(''),
      campaignId: z.string().default(''),
      adCaption: z.string().default(''),
      callToAction: z.string().default(''),
      landingPageUrl: z.string().url().default('').optional(),
    })
    .optional(),
});
