import { Close, Pause } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { PlayArrowIcon } from 'assets/icons/icons';
import { formatTime } from 'pages/CreatorBriefDetails/utils';
import { FC, useEffect, useRef, useState } from 'react';

export interface IVideoDisplayModalProps {
  isOpen: boolean;
  videoUrl?: string;
  onClose: () => void;
}
// FIXME: Clean up the code
const VideoDisplayModal: FC<IVideoDisplayModalProps> = ({
  isOpen,
  onClose,
  videoUrl,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  const videoAction = () => {
    setIsPlaying((prev) => !prev);
    if (videoRef.current && !isPlaying) {
      videoRef.current.play();
    } else if (videoRef.current && isPlaying) {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        const handleTimeUpdate = () => {
          setCurrentTime(videoElement.currentTime);
        };

        const handleLoadedMetadata = () => {
          setDuration(videoElement.duration);
        };

        videoElement.addEventListener('timeupdate', handleTimeUpdate);
        videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        return () => {
          videoElement.removeEventListener('timeupdate', handleTimeUpdate);
          videoElement.removeEventListener(
            'loadedmetadata',
            handleLoadedMetadata
          );
          setDuration(0);
          setCurrentTime(0);
        };
      }
    }, 100);
  }, [videoUrl, isOpen]);

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      PaperProps={{
        sx: { width: '688px', height: '606px', borderRadius: '16px' },
      }}
    >
      <DialogTitle>
        <Box className="flex justify-between items-center">
          <span className="uppercase font-[600] font-oswald text-[14px]">
            Creative inspiration
          </span>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ px: '13px' }}>
        <div className="flex flex-col gap-4">
          <div className="bg-[#1D1C1C] rounded-3xl flex justify-center items-center">
            <video
              autoPlay
              playsInline
              preload="auto"
              ref={videoRef}
              muted={false}
              onClick={(e) => e.preventDefault()}
              className="h-auto w-auto max-h-[431px] max-w-[632px]"
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>

          <div className="flex gap-x-4 items-center">
            <span className="min-w-[46px]">{formatTime(currentTime)}</span>

            <IconButton onClick={() => videoAction()}>
              <div className="flex justify-center items-center">
                {!isPlaying ? (
                  <PlayArrowIcon />
                ) : (
                  <Pause className="text-black text-[16px]" />
                )}
              </div>
            </IconButton>

            <LinearProgress
              color="primary"
              variant="determinate"
              value={
                currentTime && duration
                  ? parseFloat((currentTime / duration).toFixed(2)) * 100
                  : 0
              }
              valueBuffer={100}
              sx={{
                flexGrow: 1,
                backgroundColor: '#f0f0f0',
                borderRadius: '5px',
                height: '10px',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#FF872F',
                },
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default VideoDisplayModal;
