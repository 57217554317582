import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { ceilToNearestDecimal } from 'utils/utils';

export const HEADER_CELLS: GridColDef[] = [
  {
    field: 'month',
    headerName: 'month',
    filterable: false,
    editable: false,
    valueFormatter: (row, value) => format(value.month, 'MMMM'),
  },
  {
    field: 'amount',
    headerName: 'Earnings',
    filterable: false,
    editable: false,
    align: 'right',
    headerAlign: 'left',
    valueFormatter: (row, value) => `$${ceilToNearestDecimal(value.amount)}`,
  },
  {
    field: 'edit',
    headerName: 'edit',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    editable: false,
  },
  {
    field: 'add',
    headerName: 'Add',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    editable: false,
  },
];
