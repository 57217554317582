import { CreativeRequest, CREATIVE_STATUS, USER_TYPES } from 'API';
import RevisionContentModal from 'components/revisionContentModal/revisionContentModal';
import Table, { Tdata } from 'components/table/Table';
import { getCreatorBriefList } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { ICreatorBriefListProps, withCreatorBriefList } from 'state/dashboard';
import { getSubmissionRowColor } from '../adminCreativeApproval/constants';

const CreativeRequests: FC<ICreatorBriefListProps> = ({
  requestList,
  requestLoading,
  reqPagination,
  profileData,
  changePage,
}) => {
  const [tableData, setTableData] = useState<Tdata[] | null>(null);
  const [showContent, setShowContent] = useState(false);
  const [selectedRequest, setSelectedRequest] =
    useState<CreativeRequest | null>(null);
  const { getBriefList, data: briefs } = getCreatorBriefList();

  useEffect(() => {
    const data = requestList.map((req) => ({
      id: req?.id || '',
      img: req.brandProfileImage || 'images/default-image.png',
      brandName: req.brandName || 'N/A',
      activationName: req.briefName || 'N/A',
      creativeId: req?.uniqueId,
      lastUpdatedBy: req?.lastUpdatedBy,
      adminApprovalStatus: req?.adminApproval || CREATIVE_STATUS.Submitted,
      brandApprovalStatus: req?.status,
    }));

    setTableData(data);
  }, [requestList]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    if (id != null && requestList.length) {
      const foundRequest = requestList.find((req) => {
        return req.id == url.searchParams.get('id');
      });

      if (!foundRequest) {
        return;
      }

      setSelectedRequest(foundRequest as unknown as CreativeRequest);
      setShowContent(true);
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('id');

      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        currentUrl.pathname +
        currentUrl.search;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }, [requestList]);

  useEffect(() => {
    if (profileData && !briefs) {
      getBriefList({
        variables: { page: 1, pageSize: 1000, country: profileData?.country },
      });
    }
  }, [briefs, profileData]);

  return (
    <>
      <div className="grid">
        <div className="overflow-x-auto max-w-full">
          <Table
            mainlyData={requestList as Tdata[]}
            data={tableData as Tdata[]}
            header={{
              title: 'My creative submissions',
              icon: '',
              search: true,
            }}
            rows={[
              '',
              'creativeId',
              'brandName',
              'activationName',
              'adminApprovalStatus',
              'brandApprovalStatus',
            ]}
            onRowClick={(request) => {
              if (request && request.adminApproval === 'Revision') {
                setSelectedRequest(request);
                setShowContent(true);
              }
            }}
            rowClassName={(rowParam) => {
              const lastUpdatedBy = rowParam.lastUpdatedBy;
              const adminApproval = rowParam.adminApprovalStatus;

              return getSubmissionRowColor({
                lastUpdatedBy,
                adminApproval,
                userType: USER_TYPES.CREATIVE_USER,
              });
            }}
            pagination={reqPagination || 0}
            loading={requestLoading || tableData === null}
            changePage={
              changePage as (
                type: string,
                page: number,
                limit: number
              ) => Promise<void>
            }
            borderColor="#FF872F"
            firstRowName="BRAND"
            extended={true}
          />
        </div>
      </div>
      {showContent ? (
        <div
          className="inspiration-panel pointer-events-auto z-[50]"
          onClick={() => setShowContent(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="z-[99] md:w-full flex flex-col justify-center items-center w-[90%]"
          >
            <RevisionContentModal
              onClose={(): void => setShowContent(false)}
              request={selectedRequest}
              videoUrl={selectedRequest?.tiktokCreativeUrl || ''}
              createAdPayload={undefined}
              isSparkAds={false}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withCreatorBriefList(CreativeRequests);
