import { BrandBrief, ENTITIES } from 'API';
import { downloadFileFromUrl } from 'components/helpers';
import { UseExportEntitiesToCSV } from 'hooks/query/useAdminActions';
import { FC, useCallback, useEffect, useMemo } from 'react';
import ExportCard from '../ExportCard/ExportCard';

interface IInterestedTabProps {
  brandBrief: BrandBrief;
}

const InterestedTab: FC<IInterestedTabProps> = ({ brandBrief }) => {
  const {
    url,
    exportEntities,
    loading: exportCSVLoading,
  } = UseExportEntitiesToCSV();

  const onExport = useCallback(
    async (entity: ENTITIES) => {
      exportEntities({ variables: { entity, brandBriefId: brandBrief.id } });
    },
    [brandBrief.id, exportEntities]
  );

  useEffect(() => {
    if (url != null) {
      downloadFileFromUrl(url);
    }
  }, [url]);

  const nonInterestedUsersCount = useMemo(
    () =>
      brandBrief.interestedUsers?.filter((u) => !u.isInterested)?.length || 0,
    [brandBrief?.interestedUsers]
  );

  const interestedUsersCount = useMemo(
    () =>
      brandBrief.interestedUsers?.filter((u) => u.isInterested)?.length || 0,
    [brandBrief?.interestedUsers]
  );

  return (
    <div className="flex gap-x-4">
      <ExportCard
        onExport={() => onExport(ENTITIES.InterestedUsers)}
        title="Interested"
        isButtonDisabled={exportCSVLoading || !interestedUsersCount}
        value={interestedUsersCount}
      />

      <ExportCard
        isButtonDisabled={exportCSVLoading || !nonInterestedUsersCount}
        onExport={() => onExport(ENTITIES.UninterestedUsers)}
        title="Not interested"
        value={nonInterestedUsersCount}
      />
    </div>
  );
};

export default InterestedTab;
