import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
interface VideoThumbnailProps {
  videoUrl: string;
  withPlayButton?: boolean;
  loadingClassNames?: string;
  playButtonClassNames?: string;
  thumbnailWrapperClassNames?: string;
  thumbnailClassNames?: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  videoUrl,
  withPlayButton = true,
  loadingClassNames,
  playButtonClassNames,
  thumbnailWrapperClassNames,
  thumbnailClassNames,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');

  const captureThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        video.currentTime = 1;
        video.onseeked = () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL('image/png');
          setThumbnailUrl(thumbnail);
        };
      }
    }
  };

  return (
    <div>
      <video
        autoPlay
        playsInline
        preload="auto"
        muted={true}
        ref={videoRef}
        src={videoUrl}
        onLoadedMetadata={captureThumbnail}
        style={{ display: 'none' }}
        crossOrigin="anonymous"
      />
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <div
        className={classNames(
          'w-[100px] h-[150px] relative',
          thumbnailWrapperClassNames
        )}
      >
        {thumbnailUrl && (
          <PlayArrowRoundedIcon
            sx={{ color: 'white', cursor: 'pointer' }}
            className={classNames(
              'absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2',
              thumbnailClassNames
            )}
            fontSize="large"
          />
        )}
        {thumbnailUrl && withPlayButton && (
          <img
            className={classNames(
              'object-cover w-full h-full rounded-3xl',
              playButtonClassNames
            )}
            src={thumbnailUrl}
            alt="Video Thumbnail"
          />
        )}
        {!thumbnailUrl && (
          <CircularProgress
            className={classNames(
              'w-4 h-4 text-black absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2',
              loadingClassNames
            )}
          />
        )}
      </div>
    </div>
  );
};

export default VideoThumbnail;
