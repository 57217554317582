import { Button, CircularProgress } from '@mui/material';
import { CreativeRequest, CREATIVE_STATUS } from 'API';
import { Storage } from 'aws-amplify';
import { getStatusLabel } from 'components/BrandLoginView/utils';
import Video from 'components/Video/Video';
import {
  UpdateCreativeRequest,
  useCreateManualAd,
  useGetBrandBrief,
  UseGetCreativeRequest,
} from 'hooks';
import withApolloProvider from 'hooks/apollo/withApollo';
import { UseSendContentStatusChangeAlert } from 'hooks/query/useEmail';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from 'state/auth';
import { ProfileContext } from 'state/profileSteps';
import { BrandRoutes } from 'utils';
import { SUCCESS_MESSAGES, WATERMARK_WARNING_TEXT } from './constants';
import PreviewWindowModal from './PreviewWindowModal/PreviewWindowModal';

const getCreativeRequestIdFromUrl = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get('creativeRequestId');
};

const PreviewWindow = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { profileState } = useContext(ProfileContext);
  const { authState } = useContext(AuthContext);

  const { url = '' } = useParams<string>();
  const [videoUrl, setVideoUrl] = useState('');
  const [id, setId] = useState<string>('');
  const [brandAvatar, setBrandAvatar] = useState('');
  const [briefName, setBriefName] = useState('');
  const [isApprover, setIsApprover] = useState(false);
  const [modalProps, setModalProps] = useState({
    open: false,
    uniqueId: searchParams.get('uniqueId') || '',
    isApprove: false,
  });
  const isBrandView = useMemo(
    () => !!new URL(window.location.href).searchParams.get('isBrandView'),
    []
  );

  const {
    getBrandBriefData: getBrandBrief,
    briefData,
    loading: briefsLoading,
  } = useGetBrandBrief();
  const { updateRequest, loading: requestUpdateLoading } =
    UpdateCreativeRequest();
  const {
    getCreativeRequest,
    data: requestData,
    loading: requestsLoading,
  } = UseGetCreativeRequest();
  const { createManual, loading: createManualAdLoading } = useCreateManualAd();
  const { sendAlert, loading: sendAlertLoading } =
    UseSendContentStatusChangeAlert();

  useLayoutEffect(() => {
    const creativeRequestId = getCreativeRequestIdFromUrl();
    if (!creativeRequestId || briefData != null || requestData != null) {
      return;
    }

    (async () => {
      const { data: creativeRequestData } = await getCreativeRequest({
        variables: { id: creativeRequestId },
      });

      const { data: brandBriefData } = await getBrandBrief({
        variables: {
          id: creativeRequestData?.getCreativeRequest?.brandBriefId || '',
        },
      });

      const isApprover = !!brandBriefData?.getBrandBrief?.whitelist?.some(
        (e) => e.email === authState.email && e.isApprover
      );
      setIsApprover(isApprover);
    })();
  }, [getBrandBrief, getCreativeRequest, authState.email]);

  const createManualAd = async () => {
    if (!requestData) {
      return;
    }

    await createManual({
      variables: { creativeRequestId: requestData.id },
    });
    await sendAlert({
      variables: {
        creativeRequestId: requestData.id,
        moderatorEmail: profileState.data?.email || '',
      },
    });
  };

  const getAwsUrl = async (requestData?: CreativeRequest | null) => {
    if (requestData == null) {
      const videoUrl = url.split('!').join('/');
      const previewUrl = await Storage.get(
        videoUrl.startsWith('public/')
          ? videoUrl.replace('public/', '')
          : videoUrl
      );

      setVideoUrl(previewUrl);
      return;
    }

    const videoURL =
      requestData.creativePreviewUrl || requestData.tiktokCreativeUrl;
    const previewURL = await Storage.get(videoURL.replace('public/', ''));
    setVideoUrl(previewURL);
  };

  const getSocialVideoUrl = async () => {
    setVideoUrl(url?.split('!').join('/'));
  };

  useLayoutEffect(() => {
    if (
      url.toLowerCase().includes('mp4') ||
      url.toLowerCase().includes('mov')
    ) {
      getAwsUrl(requestData as CreativeRequest);
    } else {
      getSocialVideoUrl();
    }

    const pathUrl = new URL(window.location.href);
    const creativeId =
      pathUrl.searchParams.get('uniqueId') ||
      pathUrl.searchParams.get('id') ||
      '';

    setId(creativeId);
    setBrandAvatar(
      pathUrl.searchParams.get('brandImage') || '/images/default-image.png'
    );
    setBriefName(pathUrl.searchParams.get('briefName') || '');
  }, [requestData]);

  const onBack = () => navigate(BrandRoutes.BrandLoginView);

  const handleReject = async (data) => {
    const creativeRequestId = getCreativeRequestIdFromUrl();
    if (!creativeRequestId) {
      return;
    }

    const updatedBrandComment = (requestData?.brandComment || []).concat([
      data.reason,
    ]);
    await updateRequest({
      variables: {
        input: {
          id: creativeRequestId,
          status: CREATIVE_STATUS.Rejected,
          brandComment: updatedBrandComment,
        },
      },
      errorPolicy: 'ignore',
    });
    await sendAlert({
      variables: {
        creativeRequestId: creativeRequestId,
        moderatorEmail: profileState.data?.email || '',
      },
    });
    await getCreativeRequest({ variables: { id: creativeRequestId } });

    setModalProps((prev) => ({ ...prev, open: false }));
    toast.success(SUCCESS_MESSAGES.Rejected);
  };

  const handleApprove = async (data) => {
    const creativeRequestId = getCreativeRequestIdFromUrl();
    if (!creativeRequestId) {
      return;
    }

    const updatedBrandComment = (requestData?.brandComment || []).concat([
      data.reason,
    ]);
    await updateRequest({
      variables: {
        input: { id: creativeRequestId, brandComment: updatedBrandComment },
      },
      errorPolicy: 'ignore',
    });
    await createManualAd();
    await getCreativeRequest({ variables: { id: creativeRequestId } });

    setModalProps((prev) => ({ ...prev, open: false }));
    toast.success(SUCCESS_MESSAGES.Approved);
  };

  const RequestLabel = () =>
    !requestData || !isBrandView
      ? null
      : getStatusLabel({
          status: requestData.status,
          statusClassNames: 'text-[12px]',
        });

  return (
    <div className="flex flex-col justify-center items-center p-6 gap-y-10">
      <div className="w-full flex justify-between items-center">
        <div className="h-[60px] w-[185px] flex-[1] text-left">
          <img src="/images/preview-page-logo.svg" alt="EDCSquared" />
        </div>

        <div className="flex flex-col gap-y-2 self-center flex-[1] text-center">
          <h3 className="text-center font-medium font-inter text-main-black text-[16px]">
            Activation
          </h3>

          <h2 className="font-oswald font-semibold text-[24px] uppercase">
            {briefName}
          </h2>
        </div>

        <div className="flex-[1] flex justify-end">
          <div className="w-[66px] h-[66px] rounded-full border-main-black border-2 overflow-hidden">
            <img className="w-full h-full object-cover" src={brandAvatar} />
          </div>
        </div>
      </div>

      {!requestsLoading && !briefsLoading ? (
        <div className="border-2 border-gray-main rounded-[16px] h-full w-full flex justify-center items-center py-10">
          <div className="h-full flex flex-col gap-y-10 justify-center items-center">
            <div className="flex flex-col gap-y-2">
              <h2 className="font-oswald font-semibold text-14 uppercase text-center">
                Creative id: {id}
              </h2>

              <RequestLabel />
            </div>

            <Video isMuted={false} videoUrl={videoUrl} url={url} />

            <div className="text-center font-inter text-14 text-main-black">
              {WATERMARK_WARNING_TEXT}
            </div>

            {isBrandView && (
              <div className="flex gap-[10px]">
                <Button
                  onClick={onBack}
                  className="bg-main-black w-[91px] text-white font-bold font-oswald uppercase px-8 py-3 rounded-[16px]"
                >
                  Back
                </Button>

                {isApprover && (
                  <>
                    <Button
                      disabled={
                        requestData?.status === CREATIVE_STATUS.Approved ||
                        requestData?.status === CREATIVE_STATUS.Rejected
                      }
                      onClick={() =>
                        setModalProps((prev) => ({
                          ...prev,
                          open: true,
                          isApprove: false,
                        }))
                      }
                      className="disabled:bg-gray-500 bg-[#D32F2F] w-[91px] text-white font-bold font-oswald uppercase px-8 py-3 rounded-[16px]"
                    >
                      Reject
                    </Button>

                    <Button
                      disabled={
                        requestData?.status === CREATIVE_STATUS.Approved
                      }
                      onClick={() =>
                        setModalProps((prev) => ({
                          ...prev,
                          open: true,
                          isApprove: true,
                        }))
                      }
                      className="disabled:bg-gray-500 bg-[#00B1B5] w-[91px] text-white font-bold font-oswald uppercase px-8 py-3 rounded-[16px]"
                    >
                      Approve
                    </Button>
                  </>
                )}
                <PreviewWindowModal
                  isModalOpen={modalProps.open}
                  onSubmit={modalProps.isApprove ? handleApprove : handleReject}
                  onClose={() =>
                    setModalProps((prev) => ({ ...prev, open: false }))
                  }
                  title={modalProps.uniqueId}
                  isLoading={
                    requestsLoading ||
                    createManualAdLoading ||
                    requestUpdateLoading ||
                    sendAlertLoading
                  }
                  isApprove={modalProps.isApprove}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[100vh] pb-10 flex justify-center items-center">
          <CircularProgress
            sx={{ fontSize: 50 }}
            className=" w-10 h-10 text-main-teal"
          />
        </div>
      )}
    </div>
  );
};

export default withApolloProvider(() => <PreviewWindow />);
