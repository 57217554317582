import { CircularProgress } from '@mui/material';
import {
  ADMIN_STATUS,
  ApprovedAdType,
  CREATIVE_STATUS,
  SEARCH_CREATIVE_REQUESTS_RESPONSE,
} from 'API';
import { TextInput } from 'components/TextInput';
import { UseGetCreativeRequest } from 'hooks';
import { UseLinkApprovedAds } from 'hooks/query/useApprovedAds';
import useZodForm from 'hooks/useZodForm';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { z } from 'zod';
import ErrorModal from './components/ErrorModal/ErrorModal';
import { LinkAdDialogTitle } from './constants';

interface ICreativeDetailsProps {
  creativeRequest?: SEARCH_CREATIVE_REQUESTS_RESPONSE | null;
  onSuccess?: () => Promise<void>;
}

interface ILinkApprovedAdsPayload {
  tiktokAdId?: string;
  googleAdId?: string;
  metaAdId?: string;
}

const schema = z.object({
  tiktokAdId: z.string().optional(),
  googleAdId: z.string().optional(),
  metaAdId: z.string().optional(),
});

export const CreativeDetails = ({
  creativeRequest: requestData,
  onSuccess,
}: ICreativeDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { linkAds, error } = UseLinkApprovedAds();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const { data: creativeRequest, getCreativeRequest } = UseGetCreativeRequest();
  const isApproved = useMemo(
    () =>
      creativeRequest?.adminApproval === ADMIN_STATUS.Approved &&
      creativeRequest?.status === CREATIVE_STATUS.Approved,

    [creativeRequest]
  );

  const getRequest = async () => {
    if (requestData?.id) {
      setIsLoading(true);
      await getCreativeRequest({
        variables: { id: requestData.id },
        errorPolicy: 'ignore',
      });

      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (!creativeRequest && !isInitiallyLoaded) {
      getRequest();
      setIsInitiallyLoaded(true);
    }
  }, [requestData]);

  const disabledFields = useMemo(() => {
    if (!isApproved) {
      return {
        isTiktokFieldDisabled: true,
        isMetaDisabled: true,
        isGoogleDisabled: true,
      };
    }

    const approvedAds = creativeRequest?.approvedAds?.items || [];
    const tiktokAd = approvedAds?.find(
      (ad) => ad?.approvedAdType === ApprovedAdType.TIKTOK
    );

    const metaAd = approvedAds.find(
      (ad) => ad?.approvedAdType === ApprovedAdType.META
    );

    const googleAd = approvedAds.find(
      (ad) => ad?.approvedAdType === ApprovedAdType.YOUTUBE
    );

    const disabledFields = {
      isTiktokFieldDisabled:
        !isApproved ||
        (tiktokAd?.isManuallyLinked !== undefined &&
          tiktokAd.isManuallyLinked === false),
      isMetaDisabled:
        !isApproved ||
        (metaAd?.isManuallyLinked !== undefined &&
          metaAd.isManuallyLinked === false),
      isGoogleDisabled:
        !isApproved ||
        (googleAd?.isManuallyLinked !== undefined &&
          googleAd.isManuallyLinked === false),
    };

    return disabledFields;
  }, [creativeRequest?.approvedAds, isApproved]);

  const { control, handleSubmit, setValue } = useZodForm({
    schema,
    defaultValues: {
      tiktokAdId:
        creativeRequest?.approvedAds?.items?.find(
          (a) => a?.approvedAdType === ApprovedAdType.TIKTOK && a?.ad_id
        )?.ad_id || '',
      metaAdId:
        creativeRequest?.approvedAds?.items?.find(
          (a) => a?.approvedAdType === ApprovedAdType.META && a?.ad_id
        )?.ad_id || '',
      googleAdId:
        creativeRequest?.approvedAds?.items?.find(
          (a) => a?.approvedAdType === ApprovedAdType.YOUTUBE && a?.ad_id
        )?.ad_id || '',
    },
    mode: 'all',
  });

  const updateFormValues = useCallback(
    (creativeRequest) => {
      if (!creativeRequest) {
        return;
      }

      const approvedAds = creativeRequest?.approvedAds?.items || [];
      setValue(
        'tiktokAdId',
        approvedAds?.find(
          (a) => a?.approvedAdType === ApprovedAdType.TIKTOK && a?.ad_id
        )?.ad_id || ''
      );
      setValue(
        'metaAdId',
        approvedAds?.find(
          (a) => a?.approvedAdType === ApprovedAdType.META && a?.ad_id
        )?.ad_id || ''
      );
      setValue(
        'googleAdId',
        approvedAds?.find(
          (a) => a?.approvedAdType === ApprovedAdType.YOUTUBE && a?.ad_id
        )?.ad_id || ''
      );
    },
    [creativeRequest]
  );

  useEffect(() => {
    updateFormValues(creativeRequest);
  }, [creativeRequest]);

  const onSubmit = useCallback(
    async (formData: ILinkApprovedAdsPayload) => {
      if (!creativeRequest?.id) {
        return;
      }

      setIsLoading(true);
      await linkAds({
        variables: {
          creativeRequestId: creativeRequest?.id,
          tiktokData: { adId: formData?.tiktokAdId || '', advertiserId: ' ' },
          metaData: { adId: formData?.metaAdId || '', advertiserId: ' ' },
          googleData: { adId: formData?.googleAdId || '', advertiserId: ' ' },
        },
      });

      if (onSuccess) {
        await onSuccess();
      }

      await getRequest();
      setIsLoading(false);
      toast.success('Linked successfully');
    },
    [creativeRequest]
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    setIsErrorModalOpen(true);
    setIsLoading(false);
  }, [error?.message]);

  const onCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return isLoading || !creativeRequest ? (
    <div className="w-full h-full flex justify-center align-middle py-[50px]">
      <CircularProgress />
    </div>
  ) : (
    <div className="grid grid-cols-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-start-1 col-end-2">
          <div className="flex flex-col gap-y-5">
            <div>
              <TextInput
                fullWidth={true}
                label="TikTok ad ID"
                name="tiktokAdId"
                disabled={disabledFields?.isTiktokFieldDisabled}
                control={control}
              />
            </div>

            <div>
              <TextInput
                fullWidth={true}
                label="META ad ID"
                name="metaAdId"
                disabled={disabledFields?.isMetaDisabled}
                control={control}
              />
            </div>

            <div>
              <TextInput
                fullWidth={true}
                label="Google ad ID"
                name="googleAdId"
                disabled={disabledFields?.isGoogleDisabled}
                control={control}
              />
            </div>

            <div className="flex justify-end">
              <button
                disabled={requestData?.status !== CREATIVE_STATUS.Approved}
                className="bg-main-black text-white font-bold py-2 px-4 min-w-[70px] min-h-[40px] rounded-[14px] items-end disabled:bg-neutral-400 text-center align-center"
                onClick={() => handleSubmit(onSubmit)}
              >
                <div className="flex flex-col justify-center items-center">
                  {isLoading ? (
                    <CircularProgress className="w-4 h-4 text-white" />
                  ) : (
                    'Save'
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>

      <ErrorModal
        isOpen={isErrorModalOpen}
        message={error?.message || ''}
        onClose={onCloseErrorModal}
        title={LinkAdDialogTitle.FailedToLink}
      />
    </div>
  );
};
