import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CreativeRequestStatusEmailQuery,
  CreativeRequestStatusEmailQueryVariables,
  SendContentStatusAlertMutation,
  SendContentStatusAlertMutationVariables,
} from 'API';
import { sendContentStatusAlert } from 'graphql/mutations';
import { creativeRequestStatusEmail } from 'graphql/queries';
import { UseSendEmailProps } from 'hooks/utils';
import { getQuery } from 'hooks/utils/helpers';

export const useRequestStatusSendEmail = (): UseSendEmailProps => {
  const [sendEmailData, { data, loading, error }] = useLazyQuery<
    CreativeRequestStatusEmailQuery,
    CreativeRequestStatusEmailQueryVariables
  >(getQuery(creativeRequestStatusEmail));

  return { loading, sendEmailData, data, error };
};

export const UseSendContentStatusChangeAlert = () => {
  const [sendAlert, { loading }] = useMutation<
    SendContentStatusAlertMutation,
    SendContentStatusAlertMutationVariables
  >(getQuery(sendContentStatusAlert));

  return { sendAlert, loading };
};
