import { Delete, UploadFile } from '@mui/icons-material';
import { IconButton, LinearProgress } from '@mui/material';
import { FC } from 'react';

export interface IFileProgressProps {
  progress: number;
  uploadStatus: string;
  fileSize?: string;
  fileName?: string;
  onAbort?: () => void;
  onSuccess?: () => void;
  error?: boolean;
}

const FileProgress: FC<IFileProgressProps> = ({
  progress,
  fileName,
  fileSize,
  uploadStatus,
  error,
  onAbort,
}) => {
  const muiColor = error ? 'error' : 'primary';
  const statusLabel = error ? 'Failed' : uploadStatus;

  const onDeleteProgressBar = () => {
    if (!onAbort) {
      return;
    }

    onAbort();
  };

  return (
    <div className="flex justify-between items-center px-2 py-4 gap-x-4 text-red-500">
      <UploadFile color={muiColor} className="h-[40px]" />

      <div className="flex flex-col gap-y-1 flex-1">
        {fileName ? (
          <p
            className={`font-roboto text-black text-[16px] ${
              error && 'text-red-500'
            }`}
          >
            {fileName}
          </p>
        ) : null}

        <div
          className={`text-[14px] text-gray-400 flex gap-x-4 ${
            error && 'text-red-500'
          }`}
        >
          <span>{Number(fileSize) ? `${fileSize} Kb` : 'N/A'}</span>
          <span>{statusLabel}</span>
        </div>

        <LinearProgress
          variant="determinate"
          value={progress}
          valueBuffer={progress * 1.1}
          color={muiColor}
          sx={{ flexGrow: 1, height: '5px', width: '200px' }}
        />
      </div>

      <IconButton onClick={onDeleteProgressBar}>
        <Delete color={muiColor} className="text-[24px]" />
      </IconButton>
    </div>
  );
};

export default FileProgress;
